import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/common/Layout'
import { Seo } from '../components/meta/Seo'
import useMediaQuery from '../hooks/useMediaQuery'

export const query = graphql`
  query AllCategories {
    allPrismicCategory {
      nodes {
        uid
        data {
          desktop_feature_image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
          mobile_feature_image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
          category_title {
            text
          }
        }
      }
    }
  }
`
const Works = (props) => {
  const isDesktop = useMediaQuery('(min-width:699px)')

  const desktopCategories = props.data.allPrismicCategory.nodes.map(
    (el, index) => {
      return (
        <div key={index} className="category_wrapper">
          <GatsbyImage
            image={el.data.desktop_feature_image.gatsbyImageData}
            alt="img"
            className="hero-slider__image"
          />
          <a href={`/works/${el.uid}`} className="centered secondary_button">
            <span>{el.data.category_title.text}</span>
          </a>
        </div>
      )
    },
  )
  const mobileCategories = props.data.allPrismicCategory.nodes.map(
    (el, index) => {
      return (
        <div key={index} className="category_wrapper">
          <GatsbyImage
            image={el.data.mobile_feature_image.gatsbyImageData}
            alt="img"
            className="hero-slider__image"
          />
          <a href={`/works/${el.uid}`} className="centered secondary_button">
            <span>{el.data.category_title.text}</span>
          </a>
        </div>
      )
    },
  )
  return (
    <Layout>
      <Seo title="Works" description="Check all my works ." />
      <main>{isDesktop ? desktopCategories : mobileCategories}</main>
    </Layout>
  )
}

export default Works
